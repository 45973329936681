<template>
  <div>
    <v-sheet
      tile
      height="54"
      class="d-flex"
    >
      <v-btn
        icon
        class="ma-2"
        @click="$refs.calendar.prev()"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-select
        v-model="type"
        :items="types"
        dense
        outlined
        hide-details
        class="ma-2"
        label="表示方法"
      ></v-select>
      <v-spacer></v-spacer>
      <v-btn
        icon
        class="ma-2"
        @click="$refs.calendar.next()"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="value"
        :type="type"
        :events="events"
        @change="getEvents"
        @click:event="showEvent"
      ></v-calendar>
      <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card
            color="grey lighten-4"
            min-width="350px"
            flat
          >
            <v-toolbar
              :color="selectedEvent.color"
              dark
            >
              <v-toolbar-title v-html="selectedEvent.streamer"></v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <span v-html="selectedEvent.stream_name"></span>
              </v-row>
              <v-row>
                <img :src="selectedEvent.thumbnail"/>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                color="secondary"
                :href="selectedEvent.url"
                target="_blank"
              >
                YouTubeへ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
    </v-sheet>
  </div>
</template>

<script>
  // Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, getDocs } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDB4rNHNhX5LaDcsbX7FIdvDV_UPPEv8w4",
  authDomain: "muhoujin-calendar.firebaseapp.com",
  projectId: "muhoujin-calendar",
  storageBucket: "muhoujin-calendar.appspot.com",
  messagingSenderId: "318385696897",
  appId: "1:318385696897:web:26fee5ff1dd36783d49d80"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

  export default {
    data: () => ({
      type: 'month',
      types: ['month', 'week', 'day', '4day'],
      mode: 'stack',
      modes: ['stack', 'column'],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
        { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
        { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
        { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
      ],
      value: '',
      events: [
      ],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
    }),
    methods: {
      async getEvents () {
        // Firebaseからイベント情報を取得し、変数eventsに配列として格納する
        const querySnapshot = await getDocs(collection(db, "schedule"));
        const querySnapshot_collaboration = await getDocs(collection(db, "collaboration"));
        this.events = [];
        querySnapshot.forEach((doc) => {
          this.events.push({
            name: `${doc.data().streamer}：${doc.data().stream_name}`,
            start: doc.data().stream_date,
            streamer: doc.data().streamer,
            stream_name: doc.data().stream_name,
            url: 'https://www.youtube.com/watch?v=' + doc.data().video_id,
            thumbnail: doc.data().thumbnail,
          });
        });
        querySnapshot_collaboration.forEach((doc) => {
          this.events.push({
            name: `【コラボ】${doc.data().member}(枠主：${doc.data().owner}さん)`,
            streamer: `【コラボ】${doc.data().member}(枠主：${doc.data().owner}さん)`,
            start: doc.data().date,
            stream_name: doc.data().title,
            url: doc.data().url,
            color: 'orange'
          });
          console.log(doc.data().date);
        })
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
    },
  }
</script>
